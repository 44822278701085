import React, { useEffect, useState } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./pdf.css"
import { useTranslation } from 'react-i18next';

// Create Document Component
const MyDocument = (props) => {
  const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);
  const { t } = useTranslation();
  const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;

  // console.log("props", props)
  const generatePDF = () => {
    // console.log("generatePDF")
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const marginTop = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const org_name = props.org === "All" ? "All" : props.org
    const { organisations } = props.orgdata
    const organisation = organisations && organisations.filter(or => or.name === org_name)
    // const {address,city,email,phone}=organisation[0]
    const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
    const title = "Organisation: " + org_name;
    const org_unit = "Consumed kWh: " + props.reportCountRes?.total_energy
    const rfid_free_charging_energy = "Free charging consumed kWh: " + props.reportCountRes?.free_charging_total_energy
    const org_amt = "Total Revenue: " + props.reportCountRes?.total_revenue
    const free_charging_energy = props.reportCountRes?.free_charging_total_energy;
    const total_kwh = `Total kWh ${(props.reportCountRes?.total_energy - free_charging_energy).toFixed(2)}`;
    const gst_number = "GST Number: " + gstNumber;
    // console.log(total_kwh)

    const labelKeyMap = {
      "Date": "start_date",
      "Name": "customer_name",
      "Tag ID": "idtag",
      "Station Name": "stationid",
      "Operator": "operator",
      "City": "city",
      "Start time": "start_time",
      "End time": "stop_time",
      "Charging time": "consume_time",
      "Total kWh": "kwh",
      "Idle Time": "idle_consumedTime",
      "Idle Price": "idle_calculated_price",
      "Charging Amount": "charging_amount",
      "Is free charging?": "free_charging",
      "Tax Amount": "tax_amount",
      "Promotion Code": "promo_code",
      "Promotion Discount": "promo_discount",
      "Corporate Code": "corporate_code",
      "Total Amount": "total_amount"
    };

    // const headers = [["Date",
    //   "Name",
    //   "Tag ID",
    //   "Station Name",
    //   "Operator",
    //   "City",
    //   "Start time",
    //   "End time",
    //   "Charging time (HH:MM:SS)",
    //   "Total kWh",
    //   "Idle Time",
    //   "Idle Price",
    //   "Charging Amount",
    //   "Is free charging?",
    //   "Tax Amount",
    //   "Promotion Code",
    //   "Promotion Discount",
    //   "Corporate Code",
    //   "Total Amount"]];

    const headers = [props.headers.map (header => header)];

      const data = props.csvDataRes?.data?.map(elt => 
        props.headers.map(label => {
          const key = labelKeyMap[label];
          const value = elt[key];
          // Custom formatting if needed
          return typeof value === 'number' ? value.toFixed(2) : (label === 'Is free charging?' ? (value ? "Yes" : "No") : value);
        })
      );

    // const data = props.csvDataRes?.data?.map(elt =>
    //  [elt.end_date,
    // elt.customer_name,
    // elt.idtag,
    // elt.stationid,
    // elt.operator,
    // elt.city,
    // elt.start_time,
    // elt.stop_time,
    // elt.consume_time,
    // elt.kwh,
    // elt.idle_consumedTime,
    // elt.idle_calculated_price?.toFixed(2),
    // elt.charging_amount.toFixed(2),
    // elt.free_charging ? "Yes" : "No",
    // elt.tax_amount,
    // elt.promo_code,
    // elt.promo_discount,
    // elt.corporate_code,
    // elt.total_amount]);
    // console.log("invoice data", data);
    let content = {
      startY: 180,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.text(range, marginLeft, 60);
    doc.text(org_unit, marginLeft, 80);
    doc.text(rfid_free_charging_energy, marginLeft, 100);
    doc.text(total_kwh, marginLeft, 120);
    doc.text(org_amt, marginLeft, 140);
    doc.text(gst_number, marginLeft, 160);
    doc.autoTable(content);
    doc.save(props.org + "_" + props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
  }

  // calling api to download pdf
  const handleButtonClick = () => {
    // Generate the PDF
    props.csvDataList(props.start?.toISOString()?.substr(0, 10), props.end?.toISOString()?.substr(0, 10), props.org, 1, 10000000);
    setIsPdfDownloaded(true)
    // props.onClick();
  };

  // Downloading pfd on button click
  useEffect(()=>{
    if(props.csvDataRes?.statuscode === 200 && isPdfDownloaded){
      generatePDF();
      setIsPdfDownloaded(false);
    }
  },[props.csvDataRes])

  return (
    <div>
      <button className='pdfbutton' onClick={handleButtonClick} type="button">{t("PDF(Admin)")}</button>
    </div>
  )
}

export default MyDocument;